.brand-link .brand-image {
    float: none !important;
}

.elevation-3 {
    box-shadow: none !important;
}

[class*="sidebar-dark-"] {
    background-color: #022744 !important;
}

.sidebar {
    background: #013a65 !important;
}

.titleDoiTuong {
    font-weight: 700;
}

.select2-container .select2-selection--single {
    height: 37px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 6px !important;
}

.paging .page-link {
    padding: 4px 6px 4px 6px !important;
}

.link-delete,
.link-delete:hover {
    color: #ff0000;
}

.link-action {
    cursor: pointer;
    padding-right: 5px;
}

.leak-image img {
    width: 70%;
}

.btn-leak {
    background-color: #013a65 !important;
    color: #fff;
}

.btn-leak:hover {
    background-color: #174d76 !important;
    color: #fff;
}

.brand-link {
    font-size: 16px !important;
    text-align: center !important;
    font-weight: 700 !important;
}

.login-page,
.register-page {
    height: auto !important;
}

@media (max-width: 576px) {
    .login-box,
    .register-box {
        width: 100% !important;
    }
}

.login-box,
.register-box {
    width: 460px !important;
}

.product-list-link i {
    margin-right: 7px;
    font-size: 13px;
}

.product-list-link a {
    color: #000;
}
